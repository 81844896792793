import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query PlansQuery {
    allPrismicPlans {
      edges {
        node {
          id
          data {
            name
            description {
              html
            }
            number_teams
            subscriptions {
              term
              price
              braintree_plan_id
            }
          }
        }
      }
    }
  }
`;

const resolvePlan = ({ id, subscriptions, ...plan }) => ({
  id,
  ...plan,
  subscriptions: subscriptions.map(subscription => ({
    ...subscription,
  })),
});

const resolvePlans = ({ node }) => resolvePlan({ id: node.id, ...node.data });

const dataResolver = ({ allPrismicPlans }) => {
  const resolved = allPrismicPlans.edges.map(resolvePlans);
  return keysToCamel(resolved).sort((a, b) => a.numberTeams - b.numberTeams);
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
