import React, { useState } from "react";
import classNames from "classnames";
import { encodeForm } from "@utils";
import { useContactFormData } from "@queries";
import Input from "./Input";
import RichText from "./RichText";
import Button from "./Button";

const ContactForm = () => {
  const { fields, submitText, successMessage, errorMessage } =
    useContactFormData();

  const initialFormState = {};
  fields.forEach(field => {
    initialFormState[field.name] = "";
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    html: null,
  });

  const [formData, setFormData] = useState({ ...initialFormState });

  const setFormDataValue = (name, event) => {
    setFormData({
      ...formData,
      [name]: event.target.value,
    });
  };

  const clearForm = () => setFormData({ ...initialFormState });

  const handleSuccess = () => {
    setLoading(false);
    clearForm();
    setMessage({
      type: "success",
      html: successMessage.html,
    });
  };

  const handleError = () => {
    setLoading(false);
    clearForm();
    setMessage({
      type: "success",
      html: errorMessage.html,
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeForm({ "form-name": "contact-form", ...formData }),
    })
      .then(handleSuccess)
      .catch(handleError);
  };

  const inputs = fields.filter(({ sliceType }) => sliceType === "input_field");
  const textarea = fields.find(({ name }) => name === "message");

  return (
    <div className="relative">
      <form
        name="contact-form"
        className={`w-full flex flex-wrap ${
          loading ? "opacity-50" : "opacity-100"
        }`}
        onSubmit={handleSubmit}
        method="post"
        action="/contact-form/"
        data-netlify="true"
        data-netlify-honeypot="information"
      >
        <input type="hidden" name="form-name" value="contact-form" readOnly />
        <input name="information" defaultValue="" />

        {inputs.map(({ id, name, ...rest }, idx) => (
          <Input
            key={id}
            name={name}
            value={formData[name]}
            onChange={event => setFormDataValue(name, event)}
            className={classNames({
              "sm:w-1/2 sm:pr-3 my-3": true,
              "sm:pr-3": idx % 2 === 0,
              "sm:pl-3": idx % 2 !== 0,
            })}
            {...rest}
          />
        ))}

        <Input
          value={formData.message}
          onChange={event => setFormDataValue("message", event)}
          rows={5}
          className="my-3"
          {...textarea}
        />

        <div className="flex justify-center w-full mt-6">
          <Button type="submit" color="orange" text={submitText} />
        </div>
      </form>

      {message.type && message.html && (
        <div className="absolute inset-0 bg-gray-5 border-1 border-gray-3 p-6 text-center flex flex-col items-center justify-center z-10">
          <RichText html={message.html} className="text-white" />

          <Button
            color="orange"
            text="Close"
            onClick={() => setMessage({ type: null, text: null })}
            className="mt-4"
          />
        </div>
      )}
    </div>
  );
};

export default ContactForm;
