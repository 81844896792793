import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query FooterQuery {
    prismicGlobals {
      id
      data {
        footer_links {
          title
          link {
            url
            target
          }
        }
        contact {
          email
          phone
        }
        social_media {
          name
          link {
            url
            target
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { footer_links, contact, social_media } = prismicGlobals.data;
  const resolved = {
    contact: contact[0],
    links: footer_links.map((link, idx) => ({
      ...link,
      id: idx,
    })),
    social: social_media.map((link, idx) => ({
      ...link,
      id: idx,
    })),
  };

  return keysToCamel(resolved);
};

const useFooterData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useFooterData;
