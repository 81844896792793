const encodeForm = data => {
  let encodedString = "";
  Object.keys(data).forEach(prop => {
    if (encodedString.length > 0) {
      encodedString += "&";
    }
    encodedString += encodeURI(`${prop}=${data[prop]}`);
  });
  return encodedString;
};

export default encodeForm;
