import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useSEOData } from "@queries";
import imageProps from "../Image/propTypes";

function SEO({
  title: _title,
  description: _description,
  keywords: _keywords,
  image,
  lang,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  const { author } = site.siteMetadata;
  const { defaultMeta } = useSEOData();
  const title = `Legends of Rec | ${_title}`;
  const description = _description || defaultMeta.description;
  const keywords = _keywords || defaultMeta.keywords;
  const { url } = image || defaultMeta.image;
  const imageSrc = `${url}?w=1200&h=630`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1 maximum-scale=1",
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: "og:image",
          content: imageSrc,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
      ]}
    />
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: imageProps.props,
  lang: PropTypes.string,
};

SEO.defaultProps = {
  description: null,
  keywords: null,
  image: null,
  lang: "en",
};

export default SEO;
