import React from "react";
import { useFooterData } from "@queries";
import { useDimensions } from "@hooks";
import AppLink from "../AppLink";

const Footer = () => {
  const { links } = useFooterData();

  const [el, dimensions] = useDimensions();
  const { height: elHeight = 0 } = dimensions || {};

  return (
    <footer
      ref={el}
      className="relative z-100 px-4 md:px-6 flex flex-col justify-start"
      style={{ marginTop: elHeight * -1 }}
    >
      <div className="py-2 md:py-4">
        <div className="text-sm md:mr-3 text-gray-light">
          &copy; {new Date().getFullYear()} Legends of Rec, Inc
        </div>

        <div>
          {links.map(({ id, link, title }, idx) => (
            <React.Fragment key={id}>
              <AppLink
                to={link.url}
                target={link.target}
                className="mx-1 text-blue text-sm"
              >
                {title}
              </AppLink>

              {idx < links.length - 1 && <span className="text-blue">|</span>}
            </React.Fragment>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
