import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import Header from "./Header";
import Footer from "./Footer";
import Text from "../Text";

const Layout = ({
  children,
  className: _className,
  hideHeader,
  hideFooter,
}) => {
  const [{ layout }] = useAppState();

  return (
    <div
      className={classNames({
        "flex flex-col flex-1 w-full h-full": true,
        "pb-12": layout.previewMode,
      })}
    >
      {!hideHeader && <Header />}

      <main className={`flex flex-col flex-1 w-full h-full ${_className}`}>
        {children}
      </main>

      {!hideFooter && <Footer />}

      {layout.previewMode && (
        <div className="fixed z-preview left-0 right-0 bottom-0 h-12 bg-blue flex items-center px-6">
          <Text preset="label" className="text-white">
            You are viewing a content preview.
          </Text>
        </div>
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
};

Layout.defaultProps = {
  className: "",
  hideHeader: false,
  hideFooter: false,
};

export default Layout;
