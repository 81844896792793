import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveSlices from "../dataResolvers/resolveSlices";
import resolveImageData from "../dataResolvers/resolveImageData";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query DefaultSalesSheetQuery {
    prismicSalesSheets(uid: { eq: "master" }) {
      id
      uid
      type
      data {
        heading
        subheading
        body {
          __typename
          ... on PrismicSalesSheetsBodyParagraph {
            id
            slice_type
            primary {
              heading
              text {
                html
              }
            }
          }
          ... on PrismicSalesSheetsBodyList {
            id
            slice_type
            primary {
              heading
            }
            items {
              text {
                html
              }
            }
          }
          ... on PrismicSalesSheetsBodyTestimonial {
            id
            slice_type
            primary {
              text {
                html
              }
              name
              title
              photo {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicSalesSheetsBodyContact {
            id
            slice_type
            primary {
              heading
              description {
                html
              }
              email
              phone
            }
          }
        }
      }
    }
    prismicGlobals {
      id
      data {
        logo {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
    prismicHomePage {
      id
      data {
        hero_screenshot {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = ({
  prismicSalesSheets,
  prismicGlobals,
  prismicHomePage,
}) => {
  const { heading, subheading, body } = prismicSalesSheets.data;
  const { logo } = prismicGlobals.data;
  const { hero_screenshot } = prismicHomePage.data;

  const resolved = {
    heading,
    subheading,
    logo: resolveImageData({ image: logo }),
    screenshot: resolveImageData({ image: hero_screenshot }),
    body: resolveSlices(body),
  };

  return keysToCamel(resolved);
};

const useDefaultSalesSheetData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useDefaultSalesSheetData;
