import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query HeaderQuery {
    prismicGlobals {
      id
      data {
        header_links {
          title
          link {
            url
            target
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { header_links } = prismicGlobals.data;
  const resolved = {
    links: header_links.map((link, idx) => ({
      ...link,
      id: idx,
    })),
  };

  return keysToCamel(resolved);
};

const useHeaderData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useHeaderData;
