import resolveImageData from "./resolveImageData";

const resolveSlices = body => {
  const resolved = body
    .filter(block => block.primary || block.items)
    .map(block => {
      const { id, slice_type, primary, items } = block;

      switch (slice_type) {
        case "paragraph":
          return {
            id,
            slice_type,
            heading: primary.heading,
            text: primary.text,
          };
        case "list":
          return {
            id,
            slice_type,
            heading: primary.heading,
            items: items.map((item, idx) => ({ id: idx, ...item })),
          };
        case "testimonial":
          return {
            id,
            slice_type,
            text: primary.text,
            name: primary.name,
            title: primary.title,
            photo: resolveImageData({ image: primary.photo }),
          };
        case "contact":
          return {
            id,
            slice_type,
            heading: primary.heading,
            description: primary.description,
            email: primary.email,
            phone: primary.phone,
          };
        default:
          return null;
      }
    });

  return resolved;
};

export default resolveSlices;
