import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query SEOQuery {
    prismicGlobals {
      id
      data {
        default_meta_description
        default_meta_keywords
        default_meta_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const {
    default_meta_description,
    default_meta_keywords,
    default_meta_image,
  } = prismicGlobals.data;

  const resolved = {
    defaultMeta: {
      description: default_meta_description,
      keywords: default_meta_keywords,
      image: resolveImageData({ image: default_meta_image }),
    },
  };

  return keysToCamel(resolved);
};

const useSEOData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useSEOData;
