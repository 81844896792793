import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import DefaultModalClose from "./DefaultModalClose";
import ModalScreen from "./ModalScreen";
import ModalBody from "./ModalBody";

const Modal = ({
  showing,
  setShowing,
  children,
  ModalClose = DefaultModalClose,
  className = "",
  showingClassName = "",
  style = {},
  showingStyle = {},
  onShowing,
  onHiding,
  showingAnimation,
  hidingAnimation,
  verticalPosition = "center",
  horizontalPosition = "center",
  fullScreen = false,
}) => {
  const el = useRef();
  const [shownOnce, setShownOnce] = useState(false);

  const handleKeyDown = event => {
    if (event.keyCode === 27) {
      setShowing(false);
    }
  };

  useEffect(() => {
    if (showing && !shownOnce) {
      setShownOnce(true);
    }

    if (showing) {
      if (showingAnimation) {
        showingAnimation(el.current);
      }
      if (onShowing) {
        onShowing();
      }
      document.body.style.overflow = "hidden";
    }

    // Don't run hiding animation or callback until modal
    // has been shown at least once. This prevents these funcs
    // from firing when the Modal is initially rendered as hidden,
    // which is the most common use case.
    if (!showing && shownOnce) {
      if (hidingAnimation) {
        hidingAnimation(el.current);
      }
      if (onHiding) {
        onHiding();
      }
      document.body.style.overflow = "auto";
    }

    if (showing) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showing]);

  // Pass close handler into the ModalClose component
  const Close = () => <ModalClose onClick={() => setShowing(false)} />;

  return (
    <div
      ref={el}
      className={classNames(
        {
          [`fixed flex flex-col w-full transition-opacity justify-${verticalPosition} items-${horizontalPosition}`]: true,
          [`${showingClassName}`]: !!showingClassName,
          "pointer-events-auto": showing,
          "pointer-events-none": !showing,
          "opacity-100": showing && !showingAnimation,
          "opacity-0": !shownOnce || (!showing && !hidingAnimation),
        },
        className
      )}
      style={{
        ...style,
        ...(showing ? showingStyle : {}),
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <ModalScreen onClick={() => setShowing(false)} />
      <ModalBody Close={Close} fullScreen={fullScreen}>
        {children}
      </ModalBody>
    </div>
  );
};

Modal.propTypes = {
  showing: PropTypes.bool.isRequired,
  setShowing: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  ModalClose: PropTypes.func,
  className: PropTypes.string,
  showingClassName: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showingStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onShowing: PropTypes.func,
  onHiding: PropTypes.func,
  showingAnimation: PropTypes.func,
  hidingAnimation: PropTypes.func,
  verticalPosition: PropTypes.oneOf(["start", "center", "end"]),
  horizontalPosition: PropTypes.oneOf(["start", "center", "end"]),
  fullScreen: PropTypes.bool,
};

export default Modal;
