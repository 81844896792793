import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Text from "./Text";

const Button = ({
  text,
  type,
  color,
  preset,
  size,
  onClick,
  disabled,
  className: _className,
  style,
}) => {
  const className = classNames(
    {
      "inline-flex flex-col outline-none focus:outline-none items-center justify-center transition duration-150 rounded": true,
      "h-12 px-4": size === "default",
      "h-10 px-4": size === "sm",
      "h-16 px-6": size === "lg",
      [`bg-${color} hover:bg-${color}-hover text-white`]: preset === "fill",
      [`border-1 border-${color} text-${color} hover:border-${color}-hover hover:text-${color}-hover`]:
        preset === "outline",
      "opacity-50": disabled,
    },
    _className
  );

  // Click-handler
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      // eslint doesn't allow dynamically setting the type
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={className}
      onClick={handleClick}
      disabled={disabled}
      style={style}
    >
      <Text
        preset="button"
        variant={size}
        tag="span"
        className="uppercase leading-none"
        style={{ marginTop: "3px" }}
      >
        {text}
      </Text>
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["button", "submit"]),
  color: PropTypes.oneOf(["orange", "blue", "gray"]),
  preset: PropTypes.oneOf(["fill", "outline"]),
  size: PropTypes.oneOf(["default", "sm", "lg"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Button.defaultProps = {
  type: "button",
  color: "orange",
  preset: "fill",
  size: "default",
  onClick: undefined,
  disabled: false,
  className: "",
  style: {},
};

export default Button;
