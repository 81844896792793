const parseImageData = (
  { image, focal_point_x, focal_point_y, caption },
  idx
) => {
  if (!image) {
    return null;
  }

  const { url, alt, dimensions } = image;

  if (!url) {
    return null;
  }

  return {
    id: idx || 0,
    url,
    alt,
    width: dimensions.width,
    height: dimensions.height,
    fpx: focal_point_x,
    fpy: focal_point_y,
    caption,
  };
};

const resolveImageData = data => {
  if (Array.isArray(data)) {
    return data.map(parseImageData);
  }
  return parseImageData(data);
};

export default resolveImageData;
