import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query NotFoundPageQuery {
    prismicNotFoundPage {
      id
      data {
        heading
        link {
          url
          target
        }
        link_text
        meta_title
        meta_description
        meta_keywords
        meta_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicNotFoundPage }) => {
  const prefixes = ["meta_"];

  const { prefixed, unPrefixed } = resolveByPrefix(
    prismicNotFoundPage.data,
    prefixes
  );

  const resolved = {
    ...prefixed,
    ...unPrefixed,
  };

  return keysToCamel(resolved);
};

const useNotFoundPageData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useNotFoundPageData;
