import { getTailwindUtility } from "@utils";
import useWindowSize from "./useWindowSize";

const screens = getTailwindUtility("screens");

function getBreakpoints(outerWidth) {
  const breakpoints = {
    current: "default",
  };

  Object.entries(screens).forEach(([key, val]) => {
    if (typeof val === "object") {
      return;
    }

    const num = val.replace("px", "");
    breakpoints[key] = num <= outerWidth;
    breakpoints.current = num <= outerWidth ? key : breakpoints.current;
  });

  return breakpoints;
}

function useBreakpoints() {
  const { innerWidth } = useWindowSize();
  return getBreakpoints(innerWidth);
}

export default useBreakpoints;
