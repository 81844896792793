import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Imgix from "react-imgix";
import classNames from "classnames";
import { onImageLoad } from "@utils";
import imageProps from "./propTypes";

// Image - use Imgix to render optimized images, including low-res placeholders

const Image = ({
  image,
  ixParams,
  objectFit,
  disableSrcSet,
  width,
  height,
  disableDrag,
  onLoad,
}) => {
  const [loaded, setLoaded] = useState();

  const { url, alt } = image;

  useEffect(() => {
    if (loaded) {
      setLoaded(false);
    }
  }, [url, width, height]);

  // this callback is passed to the Imgix component and runs
  // when the img element is loaded into the DOM. From there we
  // pass the img to onImageLoad to check when the image is loaded
  const handleRef = img => {
    if (!img) {
      return;
    }

    if (!loaded) {
      onImageLoad(img, () => {
        // extra setTimeout for DOM rendering
        setTimeout(() => {
          setLoaded(true);
        }, 50);
      });
    } else if (onLoad) {
      onLoad(img);
    }

    if (disableDrag) {
      img.addEventListener("dragstart", e => e.preventDefault());
    }
  };

  const defaultParams = {
    auto: encodeURIComponent("compress,format"),
    fit: "crop",
    crop:
      ixParams["fp-x"] || ixParams["fp-y"]
        ? "focalPoint"
        : encodeURIComponent("faces,entropy,edges"),
  };

  if (width && height) {
    return (
      <Imgix
        src={url}
        imgixParams={{
          ...defaultParams,
          ...ixParams,
        }}
        width={width}
        height={height}
        alt={alt}
        htmlAttributes={{ ref: handleRef }}
        disableSrcSet={disableSrcSet}
        className={classNames({
          "relative z-20 transition-opacity duration-500": true,
          [`object-${objectFit}`]: true,
          "w-full h-full": objectFit !== "contain",
          "opacity-0": !loaded,
          "opacity-100": loaded,
        })}
        disableLibraryParam
      />
    );
  }

  return null;
};

Image.propTypes = {
  image: imageProps.props.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  ixParams: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  objectFit: PropTypes.oneOf(["cover", "contain"]),
  disableSrcSet: PropTypes.bool,
  disableDrag: PropTypes.bool,
  onLoad: PropTypes.func,
};

Image.defaultProps = {
  width: null,
  height: null,
  ixParams: {},
  objectFit: "cover",
  disableSrcSet: false,
  disableDrag: false,
  onLoad: null,
};

export default Image;
