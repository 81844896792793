import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import createHandler from "./createHandler";
import createObserver from "./createObserver";

const hasWindow = typeof window !== "undefined";

/**
 * Uses the `IntersectionObserver` API to toggle events when child elements
 * appear in the browser viewport.
 */

const InView = React.forwardRef(
  (
    {
      children,
      unobserveAfterEntry,
      observerOptions: _observerOptions,
      className: _className,
      inViewClassName: _inViewClassName,
      style: _style,
      inViewStyle: _inViewStyle,
      onEnter,
      onExit,
    },
    ref
  ) => {
    // Set up state value to track when element is in view
    const [inViewport, setInViewport] = useState(hasWindow ? null : true);

    // Set up ref for element being observed
    const el = useRef();

    // // Set up ref for the observer
    const observer = useRef();

    // Set up ref to track first time element is observed in viewport
    // IntersectionObserver returns false on page load, so we can use this to
    // avoid running the callback passed for `onExit`
    const hasEntered = useRef();

    // Set up IntersectionObserver once DOM is loaded
    useEffect(() => {
      if (el.current && hasWindow) {
        // Set up IntersectionObserver options
        const observerOptions = {
          rootMargin: "0px", // rootMargin must be specified in pixels or percent
          threshold: 0.5, // threhold is a number between 0 and 1; equal to proportion of element visible before triggering inViewport
          ..._observerOptions,
        };

        // Create the handler function that gets called when the target
        // element intersects the window
        const handler = createHandler(el.current, setInViewport, {
          ...observerOptions,
          unobserveAfterEntry,
        });

        // Stop current observer if it exists
        if (observer.current) {
          observer.current.disconnect();
        }

        // Create new observer and save on a ref
        observer.current = createObserver(handler, el.current, observerOptions);
      }

      return () => {
        if (observer.current) {
          observer.current.disconnect();
        }
      };
    }, [_observerOptions, unobserveAfterEntry]);

    // This allows the parent component to get access to the
    // InterSectionObserver instance
    if (ref) {
      useImperativeHandle(ref, () => ({
        getObserver: () => observer.current,
        getElement: () => el.current,
      }));
    }

    // Handle triggering callbacks
    useEffect(() => {
      if (inViewport) {
        hasEntered.current = true;

        if (onEnter) {
          onEnter();
        }
      } else if (!inViewport && hasEntered.current && onExit) {
        onExit();
      }
    }, [inViewport, onEnter, onExit]);

    // Build list of classNames from props.
    // As a convenience, we can pass the baseClassName and inViewClassName as
    // an array to the `className` prop; otherwise we can pass them as separate props
    const className = Array.isArray(_className) ? _className[0] : _className;
    const inViewClassName =
      Array.isArray(_className) && _className[1]
        ? _className[1]
        : _inViewClassName;

    // Do the same for styles
    const style = Array.isArray(_style) ? _style[0] : _style;
    const inViewStyle =
      Array.isArray(_style) && _style[1] ? _style[1] : _inViewStyle;

    return (
      <div
        ref={el}
        className={classNames({
          [`${className}`]: true,
          [`${inViewClassName}`]: inViewport === true,
        })}
        style={{ ...style, ...(inViewport === true ? inViewStyle : {}) }}
      >
        {children}
      </div>
    );
  }
);

InView.propTypes = {
  children: PropTypes.node,
  unobserveAfterEntry: PropTypes.bool,
  observerOptions: PropTypes.shape({
    rootMargin: PropTypes.string,
    threshold: PropTypes.number,
  }),
  className: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  inViewClassName: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  inViewStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
};

InView.defaultProps = {
  children: null,
  unobserveAfterEntry: false,
  observerOptions: {},
  className: "",
  inViewClassName: "",
  style: {},
  inViewStyle: {},
  onEnter: null,
  onExit: null,
};

export default InView;
