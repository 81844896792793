import React from "react";
import PropTypes from "prop-types";

const ModalScreen = ({ onClick }) => (
  <button
    type="button"
    className="absolute w-full cursor-default outline-none focus:outline-none"
    style={{ top: 0, right: 0, bottom: 0, left: 0, zIndex: 1 }}
    onClick={onClick}
    aria-label="Close modal"
  />
);

ModalScreen.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ModalScreen;
