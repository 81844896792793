import React from "react";
import Email from "@svg/email.svg";
import Phone from "@svg/phone.svg";
import Text from "./Text";
import RichText from "./RichText";
import Testimonial from "./Testimonial";
import AppLink from "./AppLink";

/* eslint react/prop-types: 0 */

const Slice = ({ sliceType, ...rest }) => {
  if (sliceType === "paragraph") {
    const { heading, text } = rest;
    return (
      <>
        {heading && (
          <Text preset="h2" className="mb-4 text-orange">
            {heading}
          </Text>
        )}
        <RichText html={text.html} className="text-lg" />
      </>
    );
  }

  if (sliceType === "list") {
    const { heading, items } = rest;
    return (
      <>
        {heading && (
          <Text preset="h2" className="mb-4 text-orange">
            {heading}
          </Text>
        )}

        <ul className="list-reset">
          {items.map(({ id, text }) => (
            <li key={id} className="mb-4">
              <RichText
                html={text.html}
                className="inline-block bg-gray-6 text-gray text-lg px-2 py-1"
              />
            </li>
          ))}
        </ul>
      </>
    );
  }

  if (sliceType === "testimonial") {
    const { text, name, title, photo } = rest;
    return (
      <div className="text-center">
        <Testimonial
          text={text}
          name={name}
          title={title}
          photo={photo}
          size="lg"
        />
      </div>
    );
  }

  if (sliceType === "contact") {
    const { email, phone } = rest;
    return (
      <div className="max-w-md mx-auto bg-gray-6 rounded p-6 pb-5 text-center">
        <AppLink
          to={`mailto:${email}`}
          target="_blank"
          className="text-blue inline-flex items-center mx-2"
        >
          <span className="block w-5 h-5 text-blue mr-1">
            <Email />
          </span>
          <span>www.legendsofrec.com</span>
        </AppLink>

        <AppLink
          to={`mailto:${email}`}
          target="_blank"
          className="text-blue inline-flex items-center mx-2"
        >
          <span className="block w-5 h-5 text-blue mr-1">
            <Email />
          </span>
          <span>{email}</span>
        </AppLink>

        <AppLink
          to={`tel:${phone}`}
          target="_blank"
          className="text-blue inline-flex items-center mx-2"
        >
          <span className="block w-5 h-5 text-blue mr-1">
            <Phone />
          </span>
          <span>{phone}</span>
        </AppLink>
      </div>
    );
  }

  return null;
};

export default Slice;
