import React, { useRef, createRef, useEffect } from "react";
import PropTypes from "prop-types";
import { gsap, Circ } from "gsap";
import InView from "./InView";

const presets = {
  fadeIn: {
    initialStyle: {
      opacity: 0,
    },
    gsapTo: {
      opacity: 1,
    },
    duration: 0.8,
  },
  scaleIn: {
    initialStyle: {
      opacity: 0,
      transform: "scale(0.96)",
    },
    gsapTo: {
      opacity: 1,
      scale: 1,
    },
    duration: 0.8,
  },
  fadeUp: {
    initialStyle: {
      opacity: 0,
      transform: "translateY(5rem)",
    },
    gsapTo: {
      opacity: 1,
      y: 0,
    },
    duration: 0.8,
  },
  fadeUpFast: {
    initialStyle: {
      opacity: 0,
      transform: "translateY(2.5rem)",
    },
    gsapTo: {
      opacity: 1,
      y: 0,
    },
    duration: 0.5,
  },
  fadeUpSlow: {
    initialStyle: {
      opacity: 0,
      transform: "translateY(5rem)",
    },
    gsapTo: {
      opacity: 1,
      y: 0,
    },
    duration: 0.8,
  },
  fadeDown: {
    initialStyle: {
      opacity: 0,
      transform: "translateY(-5rem)",
    },
    gsapTo: {
      opacity: 1,
      y: 0,
    },
    duration: 0.8,
  },
};

const AnimateIn = ({
  preset,
  threshold,
  children,
  callback,
  triggerAnimation,
  delay,
  animateInOut,
  className,
  style,
}) => {
  const { initialStyle, gsapTo, duration } = presets[preset] || {};

  const staticWrap = useRef();
  const observer = createRef();

  const onEnter = _el => {
    const el = _el || observer.current.getElement();
    if (el && preset) {
      gsap.to(el, {
        ...gsapTo,
        duration,
        delay,
        ease: Circ.easeOut,
        onComplete: () => {
          // make element have transform:none to avoid any issues that may
          // arise from children with fixed-position elements
          // https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform
          el.style.transform = "none";
        },
      });
    }

    if (callback) {
      callback();
    }
  };

  const onExit = _el => {
    const el = _el || observer.current.getElement();
    if (el && preset) {
      gsap.to(el, {
        ...initialStyle,
        duration,
        delay,
        ease: Circ.easeIn,
        onComplete: () => {
          el.style.transform = "none";
        },
      });
    }
  };

  useEffect(() => {
    if (triggerAnimation) {
      onEnter(staticWrap.current);
    } else if (animateInOut) {
      onExit(staticWrap.current);
    }
  }, [triggerAnimation]);

  // If we passed a triggerAnimation prop then use that to call onEnter
  // Otherwise, use InView to trigger when the element is in viewport
  if (typeof triggerAnimation !== "undefined") {
    return (
      <div
        ref={staticWrap}
        className={className}
        style={{ ...style, ...initialStyle }}
      >
        {children}
      </div>
    );
  }

  return (
    <InView
      onEnter={onEnter}
      onExit={animateInOut ? onExit : undefined}
      observerOptions={{
        threshold,
      }}
      unobserveAfterEntry={!animateInOut}
      className={className}
      ref={observer}
      style={{ ...style, ...initialStyle }}
    >
      {children}
    </InView>
  );
};

AnimateIn.propTypes = {
  children: PropTypes.node.isRequired,
  preset: PropTypes.string,
  threshold: PropTypes.number,
  callback: PropTypes.func,
  triggerAnimation: PropTypes.bool,
  delay: PropTypes.number,
  animateInOut: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

AnimateIn.defaultProps = {
  preset: null,
  threshold: 0.1,
  callback: null,
  triggerAnimation: undefined,
  delay: undefined,
  animateInOut: false,
  className: "",
  style: {},
};

export default AnimateIn;
