import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query ContactFormQuery {
    prismicForms(uid: { eq: "contact-form" }) {
      uid
      id
      data {
        submit_text
        success_message {
          html
        }
        error_message {
          html
        }
        body {
          __typename
          ... on PrismicFormsBodyInputField {
            id
            slice_type
            primary {
              name
              label
              type
              placeholder
              is_required
            }
          }
          ... on PrismicFormsBodyTextarea {
            id
            slice_type
            primary {
              name
              label
              placeholder
              is_required
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicForms }) => {
  const { body, ...rest } = prismicForms.data;
  const resolved = {
    ...rest,
    fields: body.map(item => ({
      id: item.id,
      sliceType: item.slice_type,
      name: item.primary.name,
      label: item.primary.label,
      placeholder: item.primary.placeholder,
      type: item.primary.type?.toLowerCase(),
      required: item.primary.is_required === "Yes",
    })),
  };
  return keysToCamel(resolved);
};

const useContactFormData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useContactFormData;
