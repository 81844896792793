import { useStaticQuery, graphql } from "gatsby";
import { keysToCamel } from "@utils";
import resolveImageData from "../dataResolvers/resolveImageData";
import mergePrismicPreview from "../mergePrismicPreview";

const query = graphql`
  query LogoQuery {
    prismicGlobals {
      id
      data {
        logo {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const resolved = {
    logo: resolveImageData({ image: prismicGlobals.data.logo }),
  };

  return keysToCamel(resolved);
};

const useLogoData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useLogoData;
