import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import LogoWords from "@svg/logoWords.svg";
import Text from "./Text";
import Image from "./Image/ImageWrap";
import imageProps from "./Image/propTypes";

const Hero = ({ logos, heading, subheading, tagline, screenshot }) => {
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);

  const logoWidth = 100;

  return (
    <div className="max-w-lg mx-auto">
      <div className="md:flex md:flex-row-reverse md:justify-center">
        <div className="py-2 sm:py-8 md:py-10 text-center md:text-left">
          <div className="flex flex-col items-center text-center mb-2">
            {logos.map(logo => (
              <React.Fragment key={logo.url}>
                <div
                  key={logo.url}
                  style={{
                    width: logoWidth,
                    height: logoWidth * (logo.height / logo.width),
                  }}
                  className="relative inline-block rounded-lg overflow-hidden"
                >
                  <Image image={logo} objectFit="contain" />
                </div>
              </React.Fragment>
            ))}

            <div className="mt-6 mb-4 w-full px-12 sm:px-0 max-w-[280px] sm:max-w-[320px]">
              <LogoWords />
            </div>

            {heading && (
              <Text preset="h1" className="my-3">
                {heading}
              </Text>
            )}

            {subheading && (
              <Text
                preset="p"
                variant="lg"
                tag="h2"
                className="text-gray-light my-2 sm:my-4 whitespace-pre-wrap"
                wrap="none"
              >
                {subheading}
              </Text>
            )}

            {tagline && (
              <div className="mt-2 sm:mt-4 max-w-sm sm:px-6">
                <Text
                  preset="tagline"
                  tag="span"
                  className="bg-orange p-1"
                  wrap="pre"
                >
                  {tagline}
                </Text>
              </div>
            )}
          </div>
        </div>

        <div className="w-full h-96 md:h-auto md:w-2/5 lg:w-1/3 relative overflow-hidden">
          <div
            className={classNames({
              "absolute left-0 top-0 right-0 bottom-0 transform transition-transform": true,
              "translate-y-1/2": !heroImageLoaded,
            })}
            style={{
              transitionDuration: "1s",
              transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1)",
            }}
          >
            <Image
              image={screenshot}
              objectFit="contain"
              onLoad={() => setHeroImageLoaded(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  logos: PropTypes.arrayOf(imageProps.props).isRequired,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  tagline: PropTypes.string,
  screenshot: imageProps.props.isRequired,
};

Hero.defaultProps = {
  tagline: null,
};

export default Hero;
