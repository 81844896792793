import { mergePrismicPreviewData } from "gatsby-source-prismic";

const hasWindow = typeof window !== "undefined";

const mergePreviewData = staticData => {
  const { __PRISMIC_PREVIEW_DATA__: previewData } = hasWindow ? window : {};
  return mergePrismicPreviewData({ staticData, previewData });
};

export default mergePreviewData;
