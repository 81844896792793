import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@hooks";
import HydrationFix from "../HydrationFix";

const ModalBody = ({ children, Close, fullScreen }) => {
  const el = useRef();
  const { innerHeight } = useWindowSize();

  const width = fullScreen ? "100%" : "auto";

  return (
    <HydrationFix>
      <div
        ref={el}
        className="relative"
        style={{ width, zIndex: 2, maxWidth: "100%" }}
      >
        <Close />
        <div
          style={{
            maxHeight: innerHeight,
            height: fullScreen ? innerHeight : undefined,
            overflow: "auto",
          }}
        >
          {children}
        </div>
      </div>
    </HydrationFix>
  );
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  Close: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default ModalBody;
