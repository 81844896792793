import { useState, useEffect, useRef } from "react";
import { supportsPassive } from "@utils";

const hasWindow = typeof window !== "undefined";

const getPosition = () => ({
  scrollX: hasWindow ? window.pageXOffset : 0,
  scrollY: hasWindow ? window.pageYOffset : 0,
});

function useScrollPosition() {
  const [position, setPosition] = useState(getPosition());
  const lastKnown = useRef({
    scrollX: 0,
    scrollY: 0,
  });
  const ticking = useRef(false);

  function handleScroll() {
    if (!hasWindow) {
      return false;
    }

    const scrollingUp = window.pageYOffset < lastKnown.current.scrollY;
    const scrollingDown = window.pageYOffset > lastKnown.current.scrollY;

    lastKnown.current.scrollX = window.pageXOffset;
    lastKnown.current.scrollY = window.pageYOffset;

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        setPosition({
          scrollX: lastKnown.current.scrollX,
          scrollY: lastKnown.current.scrollY,
          scrollingUp,
          scrollingDown,
        });
        ticking.current = false;
      });

      ticking.current = true;
    }

    return undefined;
  }

  useEffect(() => {
    if (!hasWindow) {
      return false;
    }

    window.addEventListener(
      "scroll",
      handleScroll,
      supportsPassive ? { passive: true } : false
    );

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return position;
}

export default useScrollPosition;
