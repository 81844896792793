import React from "react";
import Image from "./Image/ImageWrap";
import imageProps from "./Image/propTypes";

const Prefooter = ({ image }) => (
  <div className="Prefooter">
    <Image image={image} objectFit="contain" disableMaxDimension />
  </div>
);

Prefooter.propTypes = {
  image: imageProps.props.isRequired,
};

export default Prefooter;
