module.exports = {
  content: [
    "./src/components/**/*.js",
    "./src/pageTemplates/**/*.js",
    "./src/pages/**/*.js",
    "./src/svg/**/*.svg",
  ],
  safelist: [{ pattern: /^bg-|^hover:bg-|^active:bg-|^whitespace-/ }],
  theme: {
    extend: {
      height: {
        96: "24rem",
      },
      width: {
        125: "125%",
      },
    },
    screens: {
      xs: "360px",
      sm: "576px",
      cta: "667px",
      md: "900px",
      hero: "1008px",
      lg: "1208px",
      xl: "1800px",
    },
    fontFamily: {
      sans: ["Varela Round", "sans-serif"],
    },
    colors: {
      white: "#FFFFFF",
      blue: "rgb(39, 170, 225)",
      "blue-hover": "rgb(39,139,225)",
      orange: "rgb(247, 148, 30)",
      "orange-hover": "#e3872b",
      gray: {
        DEFAULT: "rgb(65, 64, 66)",
        "1/2": "rgba(65, 64, 66, .5)",
        "1/4": "rgba(65, 64, 66, .25)",
        light: "#999",
        1: "#131313",
        2: "#1a1a1a",
        3: "#222",
        4: "#333",
        5: " #444",
        6: "#eee",
      },
      "border-light": "rgba(255,255,255,.2)",
      transparent: "transparent",
    },
    maxWidth: theme => {
      return {
        none: "none",
        ...theme("screens"),
      };
    },
    fontSize: {
      xs: ".75rem", // 12px
      sm: ".875rem", // 14px
      base: "1rem", // 16px
      md: "1rem", // 16px
      lg: "1.25rem", // 20px
      xl: "1.5rem", // 22px
      "xl1-5": "1.75rem",
      xl2: "2.25rem", // 30px
      xl3: "3rem", // 34px
    },
    lineHeight: {
      none: 1,
      clamped: 1.1,
      tight: 1.25,
      firm: 1.35,
      DEFAULT: 1.5,
      wiggly: 1.5,
      loose: 1.6,
      gaping: 2,
    },
    letterSpacing: {
      none: "0",
      tight: ".02em",
      loose: ".05em",
      wide: ".15em",
    },
    borderWidth: {
      0: "0",
      1: "1px",
      2: "2px",
      3: "3px",
    },
    borderRadius: {
      none: "0",
      DEFAULT: ".25rem",
      md: ".5rem",
      lg: "1rem",
      full: "9999px",
    },
    boxShadow: {
      DEFAULT: "0 0 2rem rgba(0,0,0,.3)",
    },
    zIndex: {
      10: 1,
      20: 2,
      30: 3,
      40: 4,
      50: 5,
      60: 6,
      70: 7,
      80: 8,
      90: 9,
      100: 10,
      preview: 9999,
    },
    fill: theme => theme("colors"),
    stroke: theme => theme("colors"),
    // translate: {
    //   "1/4": "25%",
    //   "1/2": "50%",
    //   full: "100%",
    // },
  },
  variants: {
    backgroundColor: ["responsive, hover, group-hover, active"],
    opacity: ["group-hover"],
    // translate: ["responsive"],
  },
};
