import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const HydrationFix = ({ children }) => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, [setRendered]);

  return <React.Fragment key={rendered}>{children}</React.Fragment>;
};

HydrationFix.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HydrationFix;
