import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { usePlansData } from "@queries";
import { useBreakpoints } from "@hooks";
import Plan from "./Plan";
import AnimateIn from "./AnimateIn";

const Plans = ({ className = "" }) => {
  const [selectedTerm, setSelectedTerm] = useState("Monthly");
  const plans = usePlansData();
  const terms = ["Monthly", "Annual"];

  const { md } = useBreakpoints();

  return (
    <div className={`flex flex-col items-center ${className}`}>
      <div className="flex mb-12">
        {terms.map(term => (
          <div
            key={term}
            className={classNames({
              "bg-gray-2 text-gray-light pt-3 pb-2 px-4 border-b-3 text-center cursor-pointer": true,
              "border-orange": term === selectedTerm,
              "border-transparent": term !== selectedTerm,
            })}
            onClick={() => setSelectedTerm(term)}
            role="presentation"
          >
            {term} Billing
          </div>
        ))}
      </div>

      <div className="flex flex-wrap -m-4">
        {plans.map((plan, idx) => (
          <AnimateIn
            key={plan.id}
            preset="fadeUp"
            delay={md ? idx * 0.15 : null}
            className="w-full sm:w-1/2 md:w-1/4"
          >
            <Plan {...plan} term={selectedTerm} />
          </AnimateIn>
        ))}
      </div>
    </div>
  );
};

Plans.propTypes = {
  className: PropTypes.string,
};

export default Plans;
